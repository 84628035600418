/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "./layout.css"

const Layout = ({ children }) => {
  return (
    <div id="main-container">
      <main>{children}</main>

      <footer>
        <a href="mailto:max@mst.mn" target="_blank" rel="noopener noreferrer">Email</a>
        <a href="https://github.com/mstllc" target="_blank" rel="noopener noreferrer">GitHub</a>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
